import gql from "graphql-tag";

export const FRAGMENT_MEDIA_ITEM = gql`
  fragment MediaItemFragment on MediaItem {
    altText
    databaseId
    id
    sourceUrl
    mediaDetails {
      sizes {
        file
        fileSize
        height
        mimeType
        name
        sourceUrl
        width
      }
    }
  }
`;

export const FRAGMENT_NEIGHBORHOOD_DETAILS = gql`
  ${FRAGMENT_MEDIA_ITEM}
  fragment NeighborhoodDetailsFragment on Neighborhood {
    basePrice
    databaseId
    id
    slug
    status
    title
    types
    uri
    collection {
      collection
    }
    neighborhood {
      address1
      address2
      backgroundVideoFile
      city
      hours
      legal
      phone
      state
      status
      zip
      popularPlans {
        ... on Plan {
          databaseId
          id
          slug
          title
          floorplanDetails {
            bathroomsMax
            bathroomsMin
            bedroomsMax
            bedroomsMin
            collection
            displayTitle
            finishedSqrFt
            mainSqrFt
            features {
              feature
            }
          }
        }
      }
      saleTeamMembers {
        name
        image {
          ...MediaItemFragment
        }
      }
      thumbnail {
        ...MediaItemFragment
      }
    }
  }
`;

export const FRAGMENT_EVENT_DETAILS = gql`
  ${FRAGMENT_MEDIA_ITEM}
  ${FRAGMENT_NEIGHBORHOOD_DETAILS}
  fragment EventDetailsFragment on Event {
    content
    databaseId
    id
    slug
    title
    uri
    eventDetails {
      collection
      directionsUrl
      endDateTime
      eventSummary
      fieldGroupName
      location
      startDateTime
      image {
        ...MediaItemFragment
      }
      thumbnail {
        ...MediaItemFragment
      }
      neighborhood {
        ...NeighborhoodDetailsFragment
      }
    }
  }
`;

export const FRAGMENT_ORGANISMS = gql`
  ${FRAGMENT_MEDIA_ITEM}
  fragment OrganismsFragment on Page {
    databaseId
    id
    title
    hlOrganisms {
      hlOrganism {
        address1
        address2
        arrow
        attribution
        autoplayDelay
        autoplaySlider
        backgroundColor
        removeMargin
        backgroundImageOpacity
        backgroundOverlayColor
        border
        borderColor
        borderInset
        borderWidth
        brandWindowLayout
        button1
        button1Color
        button1Target
        button1Text
        button2
        button2Color
        button2Target
        button2Text
        button3
        button3Color
        button3Target
        button3Text
        city
        collection
        containerWidth
        contentTheme
        cssClasses
        customBackgroundOverlayColor
        description
        equalizeSlideHeights
        fieldGroupName
        formHeader
        fullBleed
        hideInfo
        hours
        hubspotFormId
        id
        latitude
        legal
        link1
        link2
        link3
        longitude
        mapArbitraryLayout
        mapWithInfoMapType
        markerTitle
        neighborhoodsMapLayout
        phone
        primaryHeadingLevel
        productPicker {
          ... on Home {
            databaseId
            id
            slug
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
            qmiHomeDetails {
              bathrooms
              bedrooms
              finishedSqrFt
              price
            }
            productStatus {
              enableSnipeLink
              fieldGroupName
              sectionId
              statusCustom
              statusMessage
            }
          }
          ... on Model {
            databaseId
            id
            slug
            status
            title
            uri
            featuredImage {
              node { 
                sourceUrl
              }
            }
            modelDetails {
              floorplan {
                ... on Plan {
                  floorplanDetails {
                    basePrice
                    bathroomsMax
                    bathroomsMin
                    bedroomsMax
                    bedroomsMin
                    finishedSqrFt
                  }
                }
              }
            }
            productStatus {
              enableSnipeLink
              fieldGroupName
              sectionId
              statusCustom
              statusMessage
            }  
          }
          ... on Plan {
            databaseId
            id
            slug
            status
            title
            uri
            floorplanDetails {
              basePrice
              bathroomsMax
              bathroomsMin
              bedroomsMax
              bedroomsMin
              collection
              displayTitle
              elevations {
                sourceUrl
              }
              finishedSqrFt
              mainSqrFt
            }
            productStatus {
              enableSnipeLink
              fieldGroupName
              sectionId
              statusCustom
              statusMessage
            }  
          }
        }
        productType
        rounded
        siteMapCtaLayout
        splitContentLayout
        state
        text1
        thumbnailCount
        title1
        title2
        title3
        type
        videoEmbedCode
        virtualTourCustomEmbedCode
        virtualTourEmbedType
        virtualTourLayout
        virtualTourUrl
        zip
        backgroundImage {
          ...MediaItemFragment
        }
        backgroundVideoFile
        gallery {
          sourceUrl
          title
          mediaDetails {
            sizes {
              file
              fileSize
              height
              mimeType
              name
              sourceUrl
              width
            }
          }
        }
        hiddenFormFields {
          inputSelector
          inputValue
        }
        image {
          ...MediaItemFragment
        }
        item {
          attribution
          backgroundColor
          backgroundVideoFile
          brandWindowLayout
          button1
          button1Color
          button1Target
          button1Text
          button2
          button2Color
          button2Target
          button2Text
          button3
          button3Color
          button3Target
          button3Text
          contentTheme
          cssClasses
          description
          fieldGroupName
          fullBleed
          id
          imageLink
          link1
          link2
          link3
          splitContentLayout
          text1
          title1
          title2
          title3
          type
          virtualTourCustomEmbedCode
          virtualTourEmbedType
          virtualTourLayout
          virtualTourUrl
          item {
            backgroundColor
            button1
            button1Color
            button1Target
            button1Text
            button2
            button2Color
            button2Target
            button2Text
            containerWidth
            cssClasses
            description
            fieldGroupName
            id
            link1
            link2
            selectHomes {
              ... on Home {
                databaseId
              }
            }
            selectPlans {
              ... on Plan {
                databaseId
              }
            }
            title1
            type
            item {
              description
              fieldGroupName
              title1
              title2
            }
          }
          backgroundImage {
            ...MediaItemFragment
          }
          gallery {
            sourceUrl
            title
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
          image {
            ...MediaItemFragment
          }
        }
        salesTeamMembers {
          name
          image {
            ...MediaItemFragment
          }
        }
      }
    }
  }
`;

export const FRAGMENT_CAMPAIGN_ORGANISMS = gql`
  ${FRAGMENT_MEDIA_ITEM}
  fragment CampaignOrganismsFragment on Campaign {
    databaseId
    id
    title
    hlOrganisms {
      hlOrganism {
        address1
        address2
        arrow
        attribution
        autoplayDelay
        autoplaySlider
        backgroundColor
        backgroundImageOpacity
        backgroundOverlayColor
        border
        borderColor
        borderInset
        borderWidth
        brandWindowLayout
        button1
        button1Color
        button1Target
        button1Text
        button2
        button2Color
        button2Target
        button2Text
        button3
        button3Color
        button3Target
        button3Text
        city
        collection
        containerWidth
        contentTheme
        cssClasses
        customBackgroundOverlayColor
        description
        equalizeSlideHeights
        fieldGroupName
        formHeader
        fullBleed
        hideInfo
        hours
        hubspotFormId
        id
        latitude
        legal
        link1
        link2
        link3
        longitude
        mapArbitraryLayout
        mapWithInfoMapType
        markerTitle
        neighborhoodsMapLayout
        phone
        primaryHeadingLevel
        productPicker {
          ... on Home {
            databaseId
            id
            slug
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
            qmiHomeDetails {
              bathrooms
              bedrooms
              finishedSqrFt
              price
            }
            productStatus {
              enableSnipeLink
              fieldGroupName
              sectionId
              statusCustom
              statusMessage
            }  
          }
          ... on Model {
            databaseId
            id
            slug
            status
            title
            uri
            featuredImage {
              node { 
                sourceUrl
              }
            }
            modelDetails {
              floorplan {
                ... on Plan {
                  floorplanDetails {
                    basePrice
                    bathroomsMax
                    bathroomsMin
                    bedroomsMax
                    bedroomsMin
                    finishedSqrFt
                  }
                }
              }
            }
            productStatus {
              enableSnipeLink
              fieldGroupName
              sectionId
              statusCustom
              statusMessage
            }  
          }
          ... on Plan {
            databaseId
            id
            slug
            status
            title
            uri
            floorplanDetails {
              basePrice
              bathroomsMax
              bathroomsMin
              bedroomsMax
              bedroomsMin
              collection
              displayTitle
              elevations {
                sourceUrl
              }
              finishedSqrFt
              mainSqrFt
            }
            productStatus {
              enableSnipeLink
              fieldGroupName
              sectionId
              statusCustom
              statusMessage
            }  
          }
        }
        productType
        rounded
        siteMapCtaLayout
        splitContentLayout
        state
        text1
        thumbnailCount
        title1
        title2
        title3
        type
        videoEmbedCode
        virtualTourCustomEmbedCode
        virtualTourEmbedType
        virtualTourLayout
        virtualTourUrl
        zip
        backgroundImage {
          ...MediaItemFragment
        }
        backgroundVideoFile
        gallery {
          sourceUrl
          title
          mediaDetails {
            sizes {
              file
              fileSize
              height
              mimeType
              name
              sourceUrl
              width
            }
          }
        }
        hiddenFormFields {
          inputSelector
          inputValue
        }
        image {
          ...MediaItemFragment
        }
        item {
          attribution
          backgroundColor
          backgroundVideoFile
          brandWindowLayout
          button1
          button1Color
          button1Target
          button1Text
          button2
          button2Color
          button2Target
          button2Text
          button3
          button3Color
          button3Target
          button3Text
          contentTheme
          cssClasses
          description
          fieldGroupName
          fullBleed
          id
          imageLink
          link1
          link2
          link3
          splitContentLayout
          text1
          title1
          title2
          title3
          type
          virtualTourCustomEmbedCode
          virtualTourEmbedType
          virtualTourLayout
          virtualTourUrl
          item {
            backgroundColor
            button1
            button1Color
            button1Target
            button1Text
            button2
            button2Color
            button2Target
            button2Text
            containerWidth
            cssClasses
            description
            fieldGroupName
            id
            link1
            link2
            selectHomes {
              ... on Home {
                databaseId
              }
            }
            selectPlans {
              ... on Plan {
                databaseId
              }
            }
            title1
            type
            item {
              description
              fieldGroupName
              title1
              title2
            }
          }
          backgroundImage {
            ...MediaItemFragment
          }
          gallery {
            sourceUrl
            title
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
          image {
            ...MediaItemFragment
          }
        }
        salesTeamMembers {
          name
          image {
            ...MediaItemFragment
          }
        }
      }
    }
    campaignDetails {
      modalImage {
        ...MediaItemFragment
      }
    }  
  }
`;

export const FRAGMENT_NEIGHBORHOOD_ORGANISMS = gql`
  ${FRAGMENT_MEDIA_ITEM}
  fragment OrganismsFragment on Neighborhood {
    databaseId
    id
    title
    hlOrganisms {
      hlOrganism {
        address1
        address2
        arrow
        attribution
        autoplayDelay
        autoplaySlider
        backgroundColor
        backgroundImageOpacity
        backgroundOverlayColor
        border
        borderColor
        borderInset
        borderWidth
        brandWindowLayout
        button1
        button1Color
        button1Target
        button1Text
        button2
        button2Color
        button2Target
        button2Text
        button3
        button3Color
        button3Target
        button3Text
        city
        collection
        containerWidth
        contentTheme
        cssClasses
        customBackgroundOverlayColor
        description
        equalizeSlideHeights
        fieldGroupName
        formHeader
        fullBleed
        hideInfo
        hours
        hubspotFormId
        id
        latitude
        legal
        link1
        link2
        link3
        longitude
        mapArbitraryLayout
        mapWithInfoMapType
        markerTitle
        neighborhoodsMapLayout
        phone
        primaryHeadingLevel
        productPicker {
          ... on Home {
            databaseId
            id
            slug
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
            qmiHomeDetails {
              bathrooms
              bedrooms
              finishedSqrFt
              price
            }
            productStatus {
              enableSnipeLink
              fieldGroupName
              sectionId
              statusCustom
              statusMessage
            }  
          }
          ... on Model {
            databaseId
            id
            slug
            status
            title
            uri
            featuredImage {
              node { 
                sourceUrl
              }
            }
            modelDetails {
              floorplan {
                ... on Plan {
                  floorplanDetails {
                    basePrice
                    bathroomsMax
                    bathroomsMin
                    bedroomsMax
                    bedroomsMin
                    finishedSqrFt
                  }
                }
              }
            }
            productStatus {
              enableSnipeLink
              fieldGroupName
              sectionId
              statusCustom
              statusMessage
            }  
          }
          ... on Plan {
            databaseId
            id
            slug
            status
            title
            uri
            floorplanDetails {
              basePrice
              bathroomsMax
              bathroomsMin
              bedroomsMax
              bedroomsMin
              collection
              displayTitle
              elevations {
                sourceUrl
              }
              finishedSqrFt
              mainSqrFt
            }
            productStatus {
              enableSnipeLink
              fieldGroupName
              sectionId
              statusCustom
              statusMessage
            }  
          }
        }
        productType
        rounded
        siteMapCtaLayout
        splitContentLayout
        state
        text1
        thumbnailCount
        title1
        title2
        title3
        type
        videoEmbedCode
        virtualTourCustomEmbedCode
        virtualTourEmbedType
        virtualTourLayout
        virtualTourUrl
        zip
        backgroundImage {
          ...MediaItemFragment
        }
        backgroundVideoFile
        gallery {
          sourceUrl
          title
          mediaDetails {
            sizes {
              file
              fileSize
              height
              mimeType
              name
              sourceUrl
              width
            }
          }
        }
        hiddenFormFields {
          inputSelector
          inputValue
        }
        image {
          ...MediaItemFragment
        }
        item {
          attribution
          backgroundColor
          backgroundVideoFile
          brandWindowLayout
          button1
          button1Color
          button1Target
          button1Text
          button2
          button2Color
          button2Target
          button2Text
          button3
          button3Color
          button3Target
          button3Text
          contentTheme
          cssClasses
          description
          fieldGroupName
          fullBleed
          id
          imageLink
          link1
          link2
          link3
          splitContentLayout
          text1
          title1
          title2
          title3
          type
          virtualTourCustomEmbedCode
          virtualTourEmbedType
          virtualTourLayout
          virtualTourUrl
          item {
            backgroundColor
            button1
            button1Color
            button1Target
            button1Text
            button2
            button2Color
            button2Target
            button2Text
            containerWidth
            cssClasses
            description
            fieldGroupName
            id
            link1
            link2
            selectHomes {
              ... on Home {
                databaseId
              }
            }
            selectPlans {
              ... on Plan {
                databaseId
              }
            }
            title1
            type
            item {
              description
              fieldGroupName
              title1
              title2
            }
          }
          backgroundImage {
            ...MediaItemFragment
          }
          gallery {
            sourceUrl
            title
            mediaDetails {
              sizes {
                file
                fileSize
                height
                mimeType
                name
                sourceUrl
                width
              }
            }
          }
          image {
            ...MediaItemFragment
          }
        }
        salesTeamMembers {
          name
          image {
            ...MediaItemFragment
          }
        }
      }
    }
  }
`;


export const BLOG_POST_FRAGMENT = gql`
  ${FRAGMENT_MEDIA_ITEM}
  fragment BlogPostFragment on BlogPost {
    content
    date
    slug
    title
    uri
    blogCategories {
      nodes {
        count
        name
        slug
      }
    }
    customPostDetails {
      excerpt
      subtitle
      thumbnail {
        ...MediaItemFragment
      }
      image {
        ...MediaItemFragment
      }
    }
  }
`;

export const TESTIMONIAL_FRAGMENT = gql`
  ${FRAGMENT_MEDIA_ITEM}
  fragment TestimonialFragment on Testimonial {
    content
    date
    slug
    title
    uri
    testimonialCategories {
      nodes {
        count
        name
        slug
      }
    }
    customPostDetails {
      excerpt
      subtitle
      thumbnail {
        ...MediaItemFragment
      }
      image {
        ...MediaItemFragment
      }
    }
  }
`;
